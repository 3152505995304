* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

body {
  background: linear-gradient(to bottom, #e0f7fa, #b2ebf2);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  background: #ffffff;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

p {
  color: #555;
  font-size: 14px;
  text-align: center;
  margin-bottom: 20px;
}

ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

ul li {
  color: #444;
  margin-bottom: 5px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  color: #444;
  margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

textarea {
  resize: vertical;
  min-height: 80px;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}

.submit-btn {
  width: 100%;
  padding: 12px;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #1565c0;
}

.thank-you {
  text-align: center;
}

.thank-you h2 {
  color: #1976d2;
}

input[type="checkbox"] {
  transform: scale(1.2);
}